import {View} from 'react-native';
import React, {useEffect, useState} from 'react';
import BetsShardHome from './bets-shard-home';
import {useRoute} from '@react-navigation/native';
import {SafeAny} from '@/types';
import {getShareOrder, getUserInfo} from './bets-shard-service';
import BetsShardWinning from './bets-shard-winning';
import {useShareToast} from '../hooks/shareToast.hooks';
import {userInfoObj} from './bets-shard-user';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import theme from '@/style';
import LinearGradient from '@basicComponents/linear-gradient';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import Text from '@basicComponents/text';
import {goTo, debounce, toSports} from '@/utils';
import {toGame} from '../game-navigate';
import {getGameURL} from '@/pages/home/home.service';
import {packageId} from '@/config';

const map = {
  dice: 'Dice',
  color: 'Color',
  quick3d: 'Digit',
  '3Digit': 'Digit',
  scratch: 'Scratch',
  matka: 'GameSatta',
  kerala: 'GameWebView',
  quickStateLottery: 'MixLottery',
  stateLottery: 'MixLottery',
};

const full = '100%';
const hidden = 'hidden';

const BetsShard = () => {
  const {calcActualSize} = useScreenSize();
  const {params} = useRoute<SafeAny>();
  const {shareFail} = useShareToast();
  const [orderInfo, setOrderInfo] = useState<SafeAny>({});
  const [userInfo, setUserInfo] = useState<userInfoObj>();

  const getSportURL = debounce((gametype: string) => {
    if (gametype === 'betby_sport') {
      goTo('BetBy');
      return;
    }
    getGameURL(gametype)
      .then((url: string) => {
        toSports(gametype.split('_')[0].toUpperCase() + ' Sports', url);
      })
      .catch(e => console.error('error', e));
  });

  const playNow = () => {
    // const jumpParams = {backPage: 'Home'} as SafeAny;
    const jumpParams = {} as SafeAny;
    switch (params.gameCode) {
      case 'quick3d':
      case '3Digit': {
        jumpParams.id = orderInfo?.rawData?.pickThreeId;
        jumpParams.pickName = orderInfo?.gameName;
        jumpParams.pickGameType =
          params.gameCode === 'quick3d' ? 'quick' : 'normal';
        // jumpParams.cycle = item.cycle;
        break;
      }
      case 'matka': {
        jumpParams.type = 'kerala';
        jumpParams.lotteryName = orderInfo?.rawData?.lotteryName;
        break;
      }
      case 'kerala': {
        const rawData = orderInfo?.rawData || {};
        jumpParams.id = orderInfo?.rawData?.matkaId;
        jumpParams.params = `data=${JSON.stringify(rawData)}`;
        break;
      }
      case 'quickStateLottery':
      case 'stateLottery': {
        const rawData = orderInfo?.rawData || {};
        jumpParams.lotteryID = rawData.lotteryID;
        jumpParams.isQuick =
          params.gameCode === 'quickStateLottery' ? '1' : '0';
        break;
      }
    }
    if (params.gameCode === 'scratch' && window) {
      const curLink = window.location.href.split('?')[0];
      const jumpLink = curLink.replace('bets-shard', 'index/scratch');
      window.location.replace(jumpLink);
    } else if (['quickRace', 'casino', 'live'].includes(params.gameCode)) {
      const rawData = orderInfo?.rawData || {};
      toGame({
        source: rawData.source,
        name: rawData.gameName,
        gameUrl: rawData.gameUrl,
        id: rawData.gameId,
        tripartiteUniqueness: rawData.tripartiteUniqueness,
      });
    } else if (params.gameCode === 'sport') {
      const rawData = orderInfo?.rawData || {};
      getSportURL(
        (rawData.source || '').toLowerCase().split(' ')[0] + '_sport',
      );
    } else {
      goTo((map as SafeAny)[params.gameCode], jumpParams);
    }
  };

  useEffect(() => {
    const {gameCode, orderNo, userId} = params;
    getShareOrder({
      packageId,
      gameCode,
      orderNo,
    }).then((ret: SafeAny) => {
      if (!ret) {
        shareFail();
        return;
      }
      const res = ret.data;
      setOrderInfo(res);
      if (res.userInviteCode) {
        localStorage.setItem('invitationCode', res.userInviteCode);
      }
      getUserInfo({userId, packageId}).then((info: SafeAny) => {
        setUserInfo(info);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <View
      style={[
        theme.position.rel,
        {
          height: full,
        },
      ]}>
      {orderInfo?.totalAwardAmount > 0 ? (
        <BetsShardWinning
          params={params}
          orderInfo={orderInfo}
          userInfo={userInfo}
        />
      ) : (
        <BetsShardHome
          params={params}
          orderInfo={orderInfo}
          userInfo={userInfo}
        />
      )}
      <View
        style={[
          theme.position.abs,
          {
            bottom: theme.paddingSize.zorro,
            width: full,
            padding: calcActualSize(theme.paddingSize.l),
          },
        ]}>
        <View
          style={[
            theme.flex.flex,
            theme.flex.center,
            {
              height: calcActualSize(48),
              borderRadius: calcActualSize(30),
              overflow: hidden,
              width: full,
            },
          ]}>
          <LinearGradient
            style={[
              theme.flex.flex,
              theme.flex.center,
              {
                width: full,
                height: calcActualSize(48),
                paddingHorizontal: calcActualSize(theme.paddingSize.l),
              },
            ]}
            start={{x: 0, y: 0}}
            end={{x: 1, y: 0}}
            colors={['#db0000', '#ad0000']}>
            <NativeTouchableOpacity
              // onPress={() => BetsShardWinningModalRef?.current?.showModal()}>
              // 跳转对应游戏
              onPress={playNow}>
              <Text
                calc
                fontSize={16}
                color={theme.basicColor.white}
                fontWeight="bold">
                Try To Win A Big Prize
              </Text>
            </NativeTouchableOpacity>
          </LinearGradient>
        </View>
      </View>
    </View>
  );
};
export default BetsShard;
